<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <div class="left">
          <span>Detalhar usuario</span>
        </div>
        <div>
          <z-input
            v-model="email"
            placeholder="Email"
            type="text"
            :hideDetails="true"
          />
        </div>
        <div>
          <z-input
            v-model="phone"
            placeholder="Telefone"
            type="text"
            :hideDetails="true"
          />
        </div>
        <div>
          <z-btn small rounded text="Filtrar" icon="mdi-filter-outline" @click="search" />
        </div>
        <z-table class="table" :headers="headers" :items="events">
          <template v-slot:[`item.date`]="{ item }">
            <span>{{ formateDate(item.date) }} </span>
          </template>
        </z-table>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-code-highlight/themes/duotone-sea.css";
import ApiClient from "@/services/ApiClient";
import moment from "moment";
// import "vue-code-highlight/themes/window.css";
export default {
  components: {},
  data() {
    return {
      http: new ApiClient(),
      email: "",
      phone: "",
      events: [],
      tableTagItems: [],
      headers: [
        { text: "Tipo", value: "templateType", sortable: false },
        { text: "Ação", value: "action", sortable: false },
        { text: "Data", value: "date", sortable: false },
        { text: "Template", value: "template", sortable: false },
        { text: "Jornada", value: "journey", sortable: false },
      ],
    };
  },
  async created() {
    console.log(this.$route.query);

    this.email = this.$route.query.email;
    this.phone = this.$route.query.phone;

    this.search();
  },
  computed: {},
  methods: {
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm:ss");
    },
    async search() {
      const res = await this.http.get(
        `audienceData/getUserHistory?email=${this.email}&phone=${this.phone}`
      );

      console.log(res)

      this.events = res;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: $z-s-1;

      & > div {
        flex: 1 1 400px;
        // border: 2px solid red;
        padding: 0.5rem;
        // width: 100%;
        // border: 1px solid red;
      }

      &__left {
        .card-key {
          background: #ececec;
          border-radius: 15px;
          padding: $z-s-1 1.5rem;
          &__line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
        .card-resource {
          .link-resource {
            font-size: 0.95rem;

            @media (max-width: 750px) {
              font-size: 0.75rem;
            }
          }
        }

        .card-parameters {
          &__table {
            height: 35vh;
            overflow-y: auto;
            @include trackScrollBar;
          }
        }
        & > div {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
      &__right {
        .tabs {
          .curl {
            margin-top: 0.5rem;
            background: #272822;
            padding: 0.8rem 0.5rem;
            color: white;
            font-size: 0.7rem;
            border-radius: 10px;
          }
        }
      }
    }

    .title-item {
      font-size: 1.25rem;
      font-weight: 600;
      display: block;
      margin-bottom: 0.5rem;
    }

    &__create-info {
      max-width: 50%;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .form {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .inpt {
          flex: 1 1 500px;
        }
      }
    }
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
